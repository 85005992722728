import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './index.css';

function App() {
  const [bots, setBots] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const paths = [
      '/status/sans',
      '/status/derick',
      '/status/erpan',
      '/status/irwan'
    ];

    const fetchStatuses = async () => {
      try {
        const allStatuses = await Promise.all(
          paths.map(path => 
            fetch(path)
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(data => data.bots)
          )
        );
        setBots(allStatuses.flat());
      } catch (err) {
        setError(err.message);
      }
    };

    fetchStatuses();
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center p-5">
      <h1 className="text-4xl font-bold mb-10 text-white">Silent Protocol™ Bot Status</h1>
      {error && <p className="text-red-500">Error: {error}</p>}
      <div className="w-full max-w-2xl space-y-4">
        {bots.length > 0 ? (
          bots.map((bot, index) => (
            <motion.div
              key={bot.name}
              initial={{ opacity: 0, translateY: 20 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`p-4 rounded-lg shadow-md ${
                bot.status === 'online' ? 'bg-green-800' : 'bg-red-800'
              }`}
            >
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold text-white">{bot.name}</h2>
                <span
                  className={`text-sm font-medium px-3 py-1 rounded-full ${
                    bot.status === 'online' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'
                  }`}
                >
                  {bot.status === 'online' ? 'Operational' : 'Down'}
                </span>
              </div>
              <div className="mt-2 text-sm text-gray-300">
                <p>Uptime: {bot.uptime}</p>
                <p>CPU: {bot.cpu}%</p>
                <p>Memory: {bot.memory} MB</p>
                <p>Started: {bot.started}</p>
              </div>
            </motion.div>
          ))
        ) : (
          <p className="text-gray-400">Loading bot status...</p>
        )}
      </div>
    </div>
  );
}

export default App;